import React, { useState } from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import Project from "../components/project";
import Hover from "../components/hover";
import Modal from "../components/modal";
import Footer from "../components/footer";
import Flux from "../components/flux";
import ArrowDown from "../components/arrowDown";
import Seo from "../components/seo"

const StudioWrapper = styled.div`

div{
    z-index: -1;
}
.studio{position:relative}
`

// markup
const IndexPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);

    const toggleModal = () => {
        setModalIsOpen((oldIsOpen) => !oldIsOpen)
    }

    const toggleModal2 = () => {
        setModalIsOpen2((oldIsOpen) => !oldIsOpen)
    }

    return (
        <>
            <div>
                <Layout>
                    <Seo title="Vertual" />
                    <Hover type="">
                        <main data-scroll data-scroll-id="update">
                            <div className="reel">
                                <Modal open={modalIsOpen} video="vertual" toggleModal={toggleModal} />
                            </div>
                            <div className="reel">
                                <Modal open={modalIsOpen2} video="gucci" toggleModal={toggleModal2} />
                            </div>
                            <ArrowDown />
                            <Flux toggleModal={toggleModal} toggleModal2={toggleModal2} />
                            <div className="studio">
                                <StudioWrapper>
                                    <Project name={"insightsproject"} start />
                                </StudioWrapper>
                            </div>
                            <div className="studio">
                                <StudioWrapper>
                                    <Project name={"romanproject"} />
                                </StudioWrapper>
                            </div>
                            <Footer page="services" />
                        </main>
                    </Hover>
                </Layout>
            </div>
        </>
    )
}

export default IndexPage


