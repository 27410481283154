import React from 'react';
import WeCreate from "../components/wecreate";
import styled from 'styled-components';
import Logo from '../assets/logo_vertual.svg'
import VideoBackground from "../components/videoBackground";
import VideoMockup from './videoMockup';
import Circle from "../components/circle";

const SuperTitle = styled.div`
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;

svg{
    fill: #fff;
    max-width: 80vw;
    position:absolute;
}

@media(max-width:1024px){
    svg{max-width: 90vw;}
}

h2{
    padding: 2rem;
    letter-spacing: .5rem;
    text-transform: uppercase;
    text-shadow: 0px 4px 12px rgb(0 0 0 / 50%);
}
`

const Wrapper = styled.div`

.circle{
    width:100vw;
    height:100vh;
    max-height: -webkit-fill-available;
    position: absolute;
}
.wecreate,
.wecreate2,
.wecreate3{
    top:0;
    width: 100vw;
    min-height: var(--app-height);
    display:flex;
    flex-direction: column;

}

@media(max-width:1024px){
    h1{font-size:12vw};

.wecreate,
.wecreate2,
.wecreate3{
    min-height:unset;
    padding: 100px 0;
}
}


.highlights{
    padding-top: 100px;
}
`

const Flux = (props) => {

    return <div>

        <Wrapper>
            <div className="kaleido">
                <VideoBackground video={"videoHomepage1"} />
            </div>
            <div className="circle">
                <Circle toggleModal={props.toggleModal} />
            </div>
            <div className="flux">
                <SuperTitle className="home">
                    <Logo />
                </SuperTitle>

                <div className="wecreate">
                    <WeCreate id={1} />
                </div>
                <div onClick={props.toggleModal} data-scroll data-scroll-direction="horizontal" data-scroll-speed="7">
                    <VideoMockup video={"videoHomepage2"} />
                </div>
                <div className="wecreate2">
                    <WeCreate id={2} />
                </div>
                <div onClick={props.toggleModal2} data-scroll data-scroll-direction="horizontal" data-scroll-speed="-7">
                    <VideoMockup video={"videoHomepage3"} />
                </div>
                <div className="wecreate3">
                    <WeCreate id={3} />
                </div>
                <div onClick={props.toggleModal2} data-scroll data-scroll-direction="horizontal" data-scroll-speed="7">
                    <VideoMockup onClick={props.toggleModal} video={"videoHomepage4"} />
                </div>
            </div>

        </Wrapper>
    </div>
};

export default Flux;