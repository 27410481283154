import React from 'react';
import styled from 'styled-components';
import LastWork from '../assets/watchthereel.svg';
import Play from '../assets/logo_vertual_triangle.svg';
const CircleWrapper = styled.div`
    position: absolute;
    width: 8rem;
    right: 2rem;
    top: calc(var(--app-height) - 12vh);
    z-index: 10;

    .play{
    display:flex;
}
.play svg{
    width: 3rem;
    cursor:pointer;
}

@media(max-width:512px){
    position: absolute;
    width: 4rem;
    right: 1rem;
    top: calc(var(--app-height) - 8vh);
    z-index: 10;

    .play svg{
    width: 1.5rem;
    height: 1.5rem;
}
}

.circleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-circle {
    -webkit-animation-name: animation-rotation-dots;
    animation-name: animation-rotation-dots;
    -webkit-animation-duration: 30s;
    animation-duration: 30s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    position: absolute;
    z-index: 0;
    width: 100%;
}


@-webkit-keyframes animation-rotation-dots{from{transform:rotate(0)}to{transform:rotate(359deg)}}@keyframes animation-rotation-dots{from{transform:rotate(0)}to{transform:rotate(359deg)}}
`

const Circle = (props) => {
    return <CircleWrapper>
        {/* <div className="circleContainer" onClick={props.toggleModal}> */}
        <a href="https://www.vertual.tv/reel-vertual/" target="_blank" rel="noreferrer">
            <div className="circleContainer">
                <div className="play">
                    <Play />
                </div>
                <div className="image-circle">
                    <LastWork />
                </div>
            </div>
        </a>
    </CircleWrapper>
};

export default Circle;